import { createApp } from 'vue'
import BootstrapVue3 from 'bootstrap-vue-3'
import { Amplify } from 'aws-amplify'
import { createPinia } from 'pinia'
import router from '@/router'
import awsconfig from '@/aws-exports'
import App from '@/App.vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
import 'mosha-vue-toastify/dist/style.css'

Amplify.configure(awsconfig)
const pinia = createPinia()
createApp(App).use(pinia).use(router).use(BootstrapVue3).mount('#app')
