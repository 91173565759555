import { defineStore } from 'pinia'
import axios from 'axios'
import { fromNumberToStringDate, StringDateTime } from '@/assets/convertDate'

type uniqueId = { uid: number }
type WatchMode = { mode: boolean }
type CommonInfo = { symbol: string; side: 'BUY' | 'SELL'; size: number }
type OrderInfo = CommonInfo & { executionType: string; timeInForce: string }
type PostParameter = OrderInfo & { price: number } & uniqueId
type PostResponse = { orderId: number; responsetime: number }
type DeleteResponse = { responsetime: number }
type SidePrice = { buy: number; sell: number }
type WatchOrder = PostResponse & SidePrice & OrderInfo
type FormParameter = SidePrice & OrderInfo
type Execute = CommonInfo & { executionId: number; price: number; timestamp: number }
type BrandPrice = { symbol: string; ask: number; bid: number }
type State = {
  watchMode: boolean
  watchOrder: WatchOrder
  executes: Execute[]
  brandPrice: BrandPrice
  uid: number
}

const sidePrice = (side: 'BUY' | 'SELL', buy: number, sell: number, price: number): SidePrice => {
  switch (side) {
    case 'BUY':
      return { buy: price, sell }
    case 'SELL':
      return { buy, sell: price }
  }
}
export const useOrderStore = defineStore('order', {
  state: (): State => ({
    watchMode: false,
    watchOrder: {
      orderId: 0,
      responsetime: 0,
      buy: 60,
      sell: 60.5,
      symbol: 'XRP',
      side: 'BUY',
      executionType: 'MARKET',
      timeInForce: 'FAS',
      size: 1
    },
    executes: [],
    brandPrice: { symbol: 'XRP', bid: 0, ask: 0 },
    uid: 0
  }),
  getters: {
    getUniqueId: (state: State): number => state.uid,
    getWatchMode: (state: State): boolean => state.watchMode,
    getOrderFlag: (state: State): boolean => state.watchOrder.orderId !== 0,
    getWatchOrderId: (state: State): number => state.watchOrder.orderId,
    getForm: (state: State): FormParameter => ({
      buy: state.watchOrder.buy,
      sell: state.watchOrder.sell,
      symbol: state.watchOrder.symbol,
      side: state.watchOrder.side,
      executionType: state.watchOrder.executionType,
      timeInForce: state.watchOrder.timeInForce,
      size: state.watchOrder.size
    }),
    getExecutes: (state: State): Execute[] => state.executes,
    isEmptyExecutes: (state: State): boolean => !(state.executes.length > 0),
    remainingExecuteSize: (state: State): number =>
      state.watchOrder.size -
      state.executes.reduce((accum: number, execute: Execute): number => accum + execute.size, 0),
    getBrandPrice: (state: State): BrandPrice => state.brandPrice,
    getOrderTimeForDisplay: (state: State): StringDateTime => {
      return state.watchOrder.responsetime !== 0
        ? fromNumberToStringDate(state.watchOrder.responsetime)
        : { year: 'XXXX', month: 'XX', day: 'XX', hour: 'XX', minute: 'XX', second: 'XX' }
    },
    getLatestExecuteTimeForDisplay: (state: State): StringDateTime => {
      return state.executes.length > 0
        ? fromNumberToStringDate(state.executes[0].timestamp)
        : { year: 'XXXX', month: 'XX', day: 'XX', hour: 'XX', minute: 'XX', second: 'XX' }
    }
  },
  actions: {
    setWatch(payload: WatchMode): void {
      this.watchMode = payload.mode
    },
    initWatcher(payload: FormParameter): void {
      const thisPostResponse = { orderId: this.watchOrder.orderId, responsetime: this.watchOrder.responsetime }
      this.setState({
        watchMode: this.watchMode,
        watchOrder: Object.assign(thisPostResponse, payload),
        executes: this.executes,
        brandPrice: this.brandPrice,
        uid: this.uid
      })
    },
    reverseSide(): void {
      switch (this.watchOrder.side) {
        case 'BUY':
          this.watchOrder.side = 'SELL'
          break
        case 'SELL':
          this.watchOrder.side = 'BUY'
          break
      }
    },
    async postOrder(payload: PostParameter): Promise<void> {
      const response = await axios.post<PostResponse>('gmo/order', payload)
      this.setState({
        watchMode: this.watchMode,
        watchOrder: Object.assign(
          response.data,
          sidePrice(payload.side, this.watchOrder.buy, this.watchOrder.sell, payload.price),
          {
            symbol: payload.symbol,
            side: payload.side,
            executionType: payload.executionType,
            timeInForce: payload.timeInForce,
            size: payload.size
          }
        ),
        executes: [],
        brandPrice: { symbol: payload.symbol, bid: 0, ask: 0 },
        uid: payload.uid
      })
    },
    async checkOrder(payload: { orderId: number }): Promise<void> {
      const response = await axios.get<Execute[]>(`gmo/info/execute/${payload.orderId}`)
      this.setState({
        watchMode: this.watchMode,
        watchOrder: this.watchOrder,
        executes: response.data.sort((a: Execute, b: Execute) => (a.timestamp > b.timestamp ? -1 : 1)),
        brandPrice: this.brandPrice,
        uid: this.uid
      })
    },
    async fetchBrandPrice(payload: { symbol: string }): Promise<void> {
      const response = await axios.get<BrandPrice>(`gmo/info/coin/brand/${payload.symbol}`)
      this.setState({
        watchMode: this.watchMode,
        watchOrder: this.watchOrder,
        executes: this.executes,
        brandPrice: response.data,
        uid: this.uid
      })
    },
    async cancelOrder(payload: { orderId: number }): Promise<void> {
      const response = await axios.delete<DeleteResponse>('gmo/order', { data: { cancelId: payload.orderId } })
      this.setUniqueId()
      this.setState({
        watchMode: false,
        watchOrder: {
          orderId: 0,
          responsetime: response.data.responsetime,
          buy: this.watchOrder.buy,
          sell: this.watchOrder.sell,
          symbol: this.watchOrder.symbol,
          side: this.watchOrder.side,
          executionType: this.watchOrder.executionType,
          timeInForce: this.watchOrder.timeInForce,
          size: this.watchOrder.size
        },
        executes: [],
        brandPrice: { symbol: this.watchOrder.symbol, bid: 0, ask: 0 },
        uid: this.uid
      })
    },
    resetOrder(): void {
      this.setState({
        watchMode: false,
        watchOrder: {
          orderId: 0,
          responsetime: 0,
          buy: this.watchOrder.buy,
          sell: this.watchOrder.sell,
          symbol: this.watchOrder.symbol,
          side: this.watchOrder.side,
          executionType: this.watchOrder.executionType,
          timeInForce: this.watchOrder.timeInForce,
          size: this.watchOrder.size
        },
        executes: [],
        brandPrice: { symbol: this.watchOrder.symbol, bid: 0, ask: 0 },
        uid: this.uid
      })
    },
    setState(payload: State): void {
      this.watchMode = payload.watchMode
      this.watchOrder = payload.watchOrder
      this.executes = payload.executes
      this.brandPrice = payload.brandPrice
      this.uid = payload.uid
    },
    setUniqueId(): void {
      this.uid = new Date().getTime()
    }
  }
})
