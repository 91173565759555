import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { useAuthStore } from '@/stores/auth'
import HomePage from '@/views/HomePage.vue'
import AppLogin from '@/views/AppLogin.vue'
import NewPassword from '@/views/NewPassword.vue'
import MainMenu from '@/views/MainMenu.vue'
import DownloadDayExecute from '@/views/DownloadDayExecute.vue'
import WriteMemo from '@/views/WriteMemo.vue'
import AutoOrder from '@/views/gmo/AutoOrder.vue'
import PriceWatcherAuto from '@/views/gmo/PriceWatcherAuto.vue'
import CoinAmount from '@/views/gmo/CoinAmount.vue'

const routes: Array<RouteRecordRaw> = [
  { path: '/', component: HomePage, meta: { public: true } },
  { path: '/login', component: AppLogin, meta: { public: true } },
  { path: '/set/password', component: NewPassword, meta: { public: true } },
  { path: '/about', component: MainMenu },
  { path: '/calendar', component: DownloadDayExecute },
  { path: '/memo', component: WriteMemo },
  { path: '/gmo/order/auto', component: AutoOrder },
  { path: '/gmo/price', component: PriceWatcherAuto },
  { path: '/gmo/coin-amount', component: CoinAmount },
  { path: '/:pathMatch(.*)*', redirect: '/' }
]

const router = createRouter({ history: createWebHistory(process.env.BASE_URL), routes })

router.beforeEach((to, _from, next): void => {
  const authStore = useAuthStore()
  if (!to.matched.some((record) => record.meta.public) && !authStore.getFlag) {
    router.push({ path: '/login', query: { redirect: to.fullPath } })
  } else {
    next()
  }
})

export default router
