import { createToast, ToastType, withProps } from 'mosha-vue-toastify'
import ToastComponent from '@/components/ToastComponent.vue'

const showTime = (type: ToastType) => {
  switch (type) {
    case 'info':
      return 2000
    case 'success':
      return 3000
    case 'warning':
      return 60000
    case 'danger':
      return -1
    default:
      return 1000
  }
}

export default (type: ToastType, title: string, description?: string) => {
  const timeout: number = showTime(type)
  const ToastOption: Record<string, string | number | boolean | ToastType> = {
    type,
    timeout,
    position: 'bottom-left',
    showCloseButton: true,
    showIcon: true,
    transition: 'slide',
    swipeClose: false
  }
  createToast(withProps(ToastComponent, { title, description }), ToastOption)
}
