<template>
  <div class="home-page" />
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
.home-page {
  width: 100%;
}
</style>
