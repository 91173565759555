import { defineStore } from 'pinia'
import axios from 'axios'

type Tweet = { id: number; name: string; value: string }
type State = { tweets: Tweet[] }

export const useTweetStore = defineStore('tweet', {
  state: (): State => ({ tweets: [] }),
  getters: {
    getTweets: (state: State): Tweet[] => state.tweets,
    getLatestTweetId: (state: State): number => (state.tweets.length > 0 ? state.tweets[0].id : 0)
  },
  actions: {
    async fetchTweets(): Promise<void> {
      const response = await axios.get<Tweet[]>('tweet')
      this.setTweets(response.data)
    },
    setTweets(tweets: Tweet[]): void {
      this.tweets = tweets
        .sort((a: Tweet, b: Tweet): number => (a.id > b.id ? -1 : 1))
        .map((item: Tweet): Tweet => ({ id: item.id, name: item.name, value: item.value }))
    }
  }
})
