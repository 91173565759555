import { defineStore } from 'pinia'
import { Auth } from 'aws-amplify'
import axios, { AxiosRequestConfig } from 'axios'
import toast from '@/assets/toast'
import { unknownErrorMessage } from '@/assets/setErrorMessage'

type State = { flag: boolean }

let InterceptId: number
const setInterceptor = (): number =>
  axios.interceptors.request.use(async (request: AxiosRequestConfig) => {
    try {
      const session = await Auth.currentSession()
      request.headers && Object.assign(request.headers, { Authorization: session.getIdToken().getJwtToken() })
      return request
    } catch (error: unknown) {
      axios.interceptors.request.eject(InterceptId)
      const errorTitle =
        typeof request.url !== 'undefined' && request.url !== '/'
          ? `API「${request.url}」の呼び出しに失敗しました。`
          : 'APIの呼び出しに失敗しました。'
      toast('danger', errorTitle, unknownErrorMessage(error))
      return Promise.reject(error)
    }
  })
export const useAuthStore = defineStore('auth', {
  state: (): State => ({ flag: false }),
  getters: { getFlag: (state: State): boolean => state.flag },
  actions: {
    async signIn(user: { name: string; pass: string }): Promise<void> {
      try {
        if (process.env.NODE_ENV === 'production') {
          await Auth.signIn(user.name, user.pass)
          axios.interceptors.request.eject(InterceptId)
          InterceptId = setInterceptor()
        }
        this.setLogin()
      } catch (error: unknown) {
        axios.interceptors.request.eject(InterceptId)
        this.unsetLogin()
        throw error
      }
    },
    async signOut(): Promise<void> {
      try {
        if (process.env.NODE_ENV === 'production') {
          await Auth.signOut()
          axios.interceptors.request.eject(InterceptId)
        }
        this.unsetLogin()
      } catch (error: unknown) {
        this.setLogin()
        throw error
      }
    },
    setLogin(): void {
      this.flag = true
    },
    unsetLogin(): void {
      this.flag = false
    }
  }
})
