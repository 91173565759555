import { AxiosError } from 'axios'

const isObject = <T extends Record<string, unknown>>(value: unknown): value is { [P in keyof T]?: unknown } =>
  typeof value === 'object' && value !== null
const isMessage = (value: unknown): value is { message: string } =>
  isObject<{ message: string }>(value) && typeof value.message === 'string'
export const axiosErrorMessage = (error: AxiosError): string => {
  return typeof error.response !== 'undefined'
    ? isMessage(error.response.data)
      ? error.response.data.message
      : 'エラーメッセージが設定されていません。'
    : '想定外のエラーが発生しました。'
}
export const unknownErrorMessage = (error: unknown): string =>
  error instanceof Error
    ? error.message !== ''
      ? error.message
      : 'エラーメッセージが設定されていません。'
    : '引数がエラー以外になっています。'
