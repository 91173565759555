const toDoubleDigits = (num: number): string => (num.toString().length === 1 ? `0${num.toString()}` : num.toString())
export type StringDateTime = { year: string; month: string; day: string; hour: string; minute: string; second: string }
export const fromNumberToStringDate = (time: number): StringDateTime => ({
  year: new Date(time).getFullYear().toString(),
  month: toDoubleDigits(new Date(time).getMonth() + 1),
  day: toDoubleDigits(new Date(time).getDate()),
  hour: toDoubleDigits(new Date(time).getHours()),
  minute: toDoubleDigits(new Date(time).getMinutes()),
  second: toDoubleDigits(new Date(time).getSeconds())
})
